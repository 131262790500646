import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Pages/Footer";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import Register from "./Pages/Register";
import Login from "./Pages/Login";
import Profile from "./Pages/Profile";
import Assets from "./Pages/Assets";
import Recharge from "./Pages/Recharge";
import Withdraw from "./Pages/Withdraw";
import CardsDetails from "./Pages/CardsDetails";
import PaymentMethod from "./Pages/PaymentMethod";
import AccountRecord from "./Pages/AccountRecord";
import WithdrawRecord from "./Pages/WithdrawRecord";

function App() {
  const Authentication = () => {
    if (!localStorage.getItem("token")) {
      return <Navigate to={"/login"}></Navigate>;
    } else {
      return <Navigate to={"/home"}></Navigate>;
    }
  };

  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={<Authentication />} />
          <Route path="/home" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/assets" element={<Assets />} />
          <Route path="/recharge" element={<Recharge />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/account-record" element={<AccountRecord/>} />
          <Route path="/withdraw-record" element={<WithdrawRecord/>} />
          
          <Route path="/card-details/:id" element={<CardsDetails />} />
          <Route path="/payment-method" element={<PaymentMethod keyContent="This text will be copied!"/>} />
          
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
