import React from "react";
import { AiFillHome } from "react-icons/ai";
import { FaFolderPlus } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row text-center text-secondary my-2">
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
              <Link to={"/"}>
                <AiFillHome className="icon " style={{ color: "silver" }} />
              </Link>
              <p style={{ fontStyle: "italic" }}>Home</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
              <Link to={"/assets"}>
                <FaFolderPlus className="icon" style={{ color: "silver" }} />
              </Link>
              <p style={{ fontStyle: "italic" }}>Assets</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
              <Link to={"/services"}>
                <FaEnvelope className="icon" style={{ color: "silver" }} />
              </Link>
              <p style={{ fontStyle: "italic" }}>Services</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
              <Link to={"/profile"}>
                <IoMdPerson className="icon" style={{ color: "silver" }} />
              </Link>
              <p style={{ fontStyle: "italic" }}>profile</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
