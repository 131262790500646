/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import img from "../assets/Images/msgg.png";
import first from "../assets/Images/frst.png";
import second from "../assets/Images/scnd.png";
import third from "../assets/Images/thrd.png";
import fourth from "../assets/Images/frth.png";
import sa from "../assets/Images/sa.jpg";
import Showing from "./Showing";
import CarousalSlider from "./Caousel";
import { HiSpeakerphone } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
// import Carousel from './Caousel'

const Home = () => {
  const navigate = useNavigate();
  const data = [
    {
      id: 1,
      image: sa,
      text: "SA VS IRE",
    },
    {
      id: 2,
      image: sa,
      text: "SA VS IRE",
    },
    {
      id: 3,
      image: sa,
      text: "SA VS IRE",
    },
  ];

  const handleNav = (val) => {
    navigate(`/card-details/${val?.id}`);
  };
  return (
    <div className="mb-5">
      <div className="container mt-4">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 className="logo-text">Cricket world</h3>
          <img src={img} alt="" height={40} width={40} />
        </div>
        <div className="col-12 text-start p-2">
          <div
            className="rounded-pill mx-2"
            style={{
              backgroundColor: "rgb(0,0,0,0.2)",
              textAlign: "left",
              paddingLeft: "15px",
            }}
          >
            <marquee className="text-white">
              <HiSpeakerphone />
              1: Welcome to CRICKET FRENZY! 2: CRICKET FRENZY is a revolution in
              how cricket is followed and celebrated worldwide! 3: Powered by
              cutting-edge technology, real-time updates, and expert analysis,
              our mission is to bring you closer to the game! 4: Good news! The
              CRICKET FRENZY global platform is live—complete simple tasks and
              win exclusive cricket merchandise!{" "}
            </marquee>
          </div>
        </div>
      </div>
      {/* <Carousel/> */}
      <div className="mt-4" style={{ width: "95%", margin: "auto" }}>
        <CarousalSlider />
      </div>
      <div className="container">
        <div className="row text-center mt-3">
          <div className="col-lg-3 col-md-3 col-sm-3 col-3">
            <Link to={"/recharge"}><img src={first} alt="" /></Link>
            <p className="logo-text fw-bold mt-2">Recharge</p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-3">
            <Link to={"/withdraw"}><img src={second} alt="" /></Link>
            <p className="logo-text fw-bold mt-2">Withdraw</p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-3">
            <img src={third} alt="" />
            <p className="logo-text fw-bold mt-2">Film </p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-3">
            <Link to={"/services"}><img src={fourth} alt="" /></Link>
            <p className="logo-text fw-bold mt-2">Help </p>
          </div>
        </div>
        <h3 className="logo-text fw-bold">Popular List</h3>
        <div className="row">
          {data?.map((val) => (
            <>
              <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                <div
                  class="card"
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: "15px",
                  }}
                >
                  <img
                    className="card-img-top"
                    src={val?.image}
                    alt="Card image cap"
                    onClick={() => handleNav(val)}
                  />
                  <div className="card-body">
                    <h6 className="card-title logo-text fst-italic fw-bold">
                      {val?.text}
                    </h6>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <Showing />
    </div>
  );
};

export default Home;
