import React, { useState } from "react";
import logo from "../assets/Images/logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import { UserRegister } from "../DAL/Auth/auth";
import ToastNotifier from "../components/ToastNotifier";
import { useSnackbar } from "notistack";
import { FormHelperText } from "@mui/material";

const Register = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState([]);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    confirm_password: "",
    code: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs?.password !== inputs?.confirm_password) {
      enqueueSnackbar("New password and confirm password does not match", {
        variant: "error",
      });
      return;
    }
    const formData = new FormData();
    formData.append("email", inputs?.email);
    formData.append("password", inputs?.password);
    formData.append("password_confirmation", inputs?.confirm_password);
    formData.append("reference_code", inputs?.code);

    const resp = await UserRegister(formData);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate("/login");
    } else {
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        setError(resp?.message);
        enqueueSnackbar("Please fill the required fields", {
          variant: "error",
        });
      }
    }
  };
  return (
    <div>
      <div className="login mb-4">
        <div className="">
          <div className="text-center">
            <img src={logo} alt="" height={200} />
            <h1 className="text-white">Cricket World</h1>
            <div className="login1">
              <input
                type="text"
                name="email"
                onChange={handleChange}
                placeholder="Enter Your Email Address *"
                style={{
                  width: "90%",
                  height: "50px",
                  backgroundColor: "transparent",
                  color: "white",
                }}
              />
              <FormHelperText
                className="text-danger"
                sx={{ fontSize: "13px", marginLeft: "5rem" }}
              >
                {error?.email && error?.email[0]}
              </FormHelperText>
              <input
                type="text"
                name="password"
                onChange={handleChange}
                placeholder="Enter 6-15 digit Password *"
                style={{
                  width: "90%",
                  height: "50px",
                  backgroundColor: "transparent",
                  marginTop: "20px",
                  color: "white",
                }}
              />{" "}
              <FormHelperText
                className="text-danger"
                sx={{ fontSize: "13px", marginLeft: "5rem" }}
              >
                {error?.password && error?.password[0]}
              </FormHelperText>
              <br />
              <input type="checkbox" className="" />
              <label className="text-white"> Show Password</label>
              <br />
              <input
                type="text"
                name="confirm_password"
                onChange={handleChange}
                placeholder="Enter 6-15 digit Password again *"
                style={{
                  width: "90%",
                  height: "50px",

                  backgroundColor: "transparent",
                  marginTop: "20px",
                  color: "white",
                }}
              />
              <input
                type="text"
                name="code"
                placeholder="Refferal Code (Optional)"
                onChange={handleChange}
                style={{
                  width: "90%",
                  height: "50px",
                  backgroundColor: "transparent",
                  marginTop: "20px",
                  color: "white",
                }}
              />
              <button className="btn1 mt-3" onClick={handleSubmit}>
                Register
              </button>
              <Link to={"/login"} style={{ textDecoration: "none" }}>
                {" "}
                <p className="text-secondary">
                  Already have an Account? <p>Login Now </p>
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
