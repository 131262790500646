import React, { useState } from "react";
import logo from "../assets/Images/logo.jpg";
import { UserLogin } from "../DAL/Auth/auth";
import { useSnackbar } from "notistack";
import { FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("email", inputs?.email);
    formData.append("password", inputs?.password);

    const resp = await UserLogin(formData);
    console.log(resp, "sahjdgjhsgfjgds");
    if (resp?.status === true) {
      localStorage.setItem("user_data", JSON.stringify(resp?.data));
      localStorage.setItem("token", resp?.data?.token);
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate("/");
    } else {
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        setError(resp?.message);
        enqueueSnackbar("Please fill the required fields", {
          variant: "error",
        });
      }
    }
  };
  return (
    <>
      <div className="login">
        <div className="">
          <div className="text-center">
            <img src={logo} alt="" height={200} />
            <h1 className="text-white">Cricket World</h1>
            <div className="login1">
              <input
                type="text"
                name="email"
                onChange={handleChange}
                placeholder="Enter Your Email Address"
                style={{
                  width: "90%",
                  height: "50px",
                  backgroundColor: "transparent",
                  color: "white",
                }}
              />
              <FormHelperText
                className="text-danger"
                style={{ fontSize: "13px", marginLeft: "5rem" }}
              >
                {error?.email && error?.email[0]}
              </FormHelperText>
              <input
                type="text"
                name="password"
                onChange={handleChange}
                placeholder="Password"
                style={{
                  width: "90%",
                  height: "50px",
                  backgroundColor: "transparent",
                  marginTop: "20px",
                  color: "white",
                }}
              />
              <FormHelperText
                className="text-danger"
                style={{ fontSize: "13px", marginLeft: "5rem" }}
              >
                {error?.password && error?.password[0]}
              </FormHelperText>
              <div style={{ width: "90%", margin: "auto" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <input type="checkbox" />
                    <label className="text-white"> Remeber me</label>
                    <br />
                  </div>
                  <p className="text-white">Forget Password</p> <br />
                </div>
              </div>
              <button className="btn1" onClick={handleSubmit}>
                Log in
              </button>
              <p className="text-secondary">
                Don’t have an Account? <a href="/register">Register Now</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
