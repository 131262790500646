import React, { useEffect, useState } from "react";
import logo from "../assets/Images/logo.jpg";
import one from "../assets/Images/1.webp";
import two from "../assets/Images/22.webp";
import three from "../assets/Images/33.webp";
import four from "../assets/Images/4.webp";
import five from "../assets/Images/5.webp";
import six from "../assets/Images/6.webp";
import seven from "../assets/Images/7.webp";
import eight from "../assets/Images/8.webp";
import nine from "../assets/Images/9.webp";
import { UserLogout } from "../DAL/Auth/auth";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ShowBalance } from "../DAL/Balance/Balance";

const Profile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [balance, setBalance] = useState();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  console.log(data, "dsjkfjksdhjkfh");

  const handleLogout = async (e) => {
    e.preventDefault();
    const resp = await UserLogout();
    if (resp?.status === true) {
      localStorage.clear();
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate("/");
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };
  const getBalance=async()=>{
    const resp = await ShowBalance();
    if(resp.status===true){
      setBalance(resp.data.balance);
    }
  }
  useEffect(()=>{
    getBalance();
  })

  useEffect(() => {
    const userData = localStorage.getItem("user_data");
    setData(JSON.parse(userData));
  }, []);
  return (
    <>
      <div style={{ backgroundColor: "#1D1D2A", paddingBottom: "100px" }}>
        <div className="container ">
          <div className="text-center text-white container profile">
            <h1>Personal Center</h1>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <img src={logo} alt="" width={70} />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-start">
                <h4>{data?.email}</h4>
                <hr style={{ border: "2px solid #E03F4E", width: "20%" }} />
                <p style={{ fontSize: "10px" }}>
                  <span
                    className="p-1"
                    style={{
                      border: "1px solid white",
                      borderRadius: "5px",
                      fontSize: "9px",
                    }}
                  >
                    Lv 0
                  </span>{" "}
                  Next Level : 0/30/ Members:0/3
                </p>
              </div>
            </div>

            <div>
              <div
                className="row"
                style={{ backgroundColor: "#EF6B77", borderRadius: "10px" }}
              >
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-center py-4">
                  <h5 style={{ fontStyle: "italic" }}>{balance}</h5>
                  <h5 style={{ fontStyle: "italic" }}>Current Balance</h5>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-6 text-center py-4"
                  style={{ borderLeft: "1px solid white" }}
                >
                  <h5 style={{ fontStyle: "italic" }}>3.07</h5>
                  <h5 style={{ fontStyle: "italic" }}>Current Balance</h5>
                </div>
              </div>
              <div style={{ backgroundColor: "#282834", borderRadius: "10px" }}>
                <div className="row text-center my-3 p-3">
                  <div className="col-lg-4 col-sm-4 col-md-4 col-4">
                    <img src={one} alt="" width={70} />
                    <h6 style={{ fontStyle: "italic" }}>Recharge</h6>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-md-4 col-4">
                    <img src={two} alt="" width={70} />
                    <h6 style={{ fontStyle: "italic" }}>Team Earnings</h6>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-md-4 col-4">
                    <img src={three} alt="" width={70} />
                    <h6 style={{ fontStyle: "italic" }}>Investment Cricket</h6>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#212529",
                      borderRadius: "36px",
                      width: "95%",
                      margin: "auto",
                    }}
                  >
                    <div className="text-end ">
                      <button
                        style={{
                          backgroundColor: "#FFC107",
                          borderRadius: "36px",
                          border: "none",
                          color: "black",
                          fontStyle: "italic",
                        }}
                      >
                        Withdraw Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <Link to={'/account-record'}><img src={four} alt="" width={70} /></Link>
                  <h6 style={{ fontStyle: "italic" }}>Acount Record</h6>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <Link to={'/withdraw-record'}><img src={five} alt="" width={70} /></Link>
                  <h6 style={{ fontStyle: "italic" }}>Electronic Wallet</h6>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <img src={six} alt="" width={70} />
                  <h6 style={{ fontStyle: "italic" }}>Membership lvl</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <img src={seven} alt="" width={70} />
                  <h6 style={{ fontStyle: "italic" }}>Box Office Guide</h6>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <img src={eight} alt="" width={70} />
                  <h6 style={{ fontStyle: "italic" }}>User Agreement</h6>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <img src={nine} alt="" width={70} />
                  <h6 style={{ fontStyle: "italic" }}>Share code</h6>
                </div>
              </div>
              <button
                style={{
                  backgroundColor: "#E14352",
                  width: "90%",
                  height: "50px",
                  border: "none",
                  borderRadius: "10px",
                  color: "white",
                  fontSize: "20px",
                  fontStyle: "italic",
                }}
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
